<template>
    <section class="dashboard dashboard--users">
        <header class="dashboard__header">
            <h3 class="heading3">Users</h3>
        </header>

        <div class="dashboard__content">
            <div v-if="loadingUsers">Loading...</div>
            <ul class="dashboard__stats" v-if="!loadingUsers">
                <li>
                    <div class="dashboard__stats__counter">
                        <animated-number
                            :value="usersCount.active"
                            :duration="500"
                            :round="true"
                        />
                    </div>
                    <p>of {{ usersCount.all }} users logged in</p>
                </li>
                <li>
                    <div class="dashboard__stats__counter">{{ usersCount.editors }}</div>
                    <p>of {{ usersCount.all }} users added or edited content</p>
                </li>
            </ul>

            <div class="dashboard__content__inner" v-if="!loadingUsers">
                <b-row>
                    <b-col sm="12">
                        <h4 class="heading4">Most active users</h4>
                        <ul class="rows-list rows-list--secondary rows-list--2cols">
                            <li v-for="user in mostActiveUsers" :key="user.id">
                                <figure class="image-block image-block--rounded">
                                    <Avatar
                                        :username="user.name"
                                        :src="user.avatar"
                                        :size="30">
                                    </Avatar>
                                </figure>
                                <div>
                                    <h5 class="heading5 heading5--secondary">{{ user.name}}</h5>
                                    <p>{{user.cnt.toLocaleString()}} {{stringHelper.getNoun('edit', user.cnt)}}</p>
                                </div>
                            </li>
                        </ul>
                    </b-col>
                </b-row>
            </div>
        </div>
    </section>
</template>

<script>
import Avatar from "vue-avatar";
import AnimatedNumber from "animated-number-vue"
import dashboard from "@/services/dashboard.service";

export default {
    props: {
        filters: Object,
    },
    data() {
        return {
            loadingUsers: false,
            allUsersCount: 0,
            usersCount: {
                all: 0,
                active: 0,
                editors: 0,
            },

            mostActiveUsers: [],
        }
    },
    components: {
        AnimatedNumber,
        Avatar,
    },

    mounted() {
        this.getUsers()

        this.$store.watch(state => {
            if (state.needUpdateUsers) {
                this.getUsers()
            }
        })
    },

    watch: {
        filters() {
            this.getUsers();
        },
    },

    methods: {
        async getUsers() {
            this.loadingUsers = true;

            const response1 = await dashboard.getWidgetData('UsersCount', this.filters);
            this.usersCount = response1.data;

            const response2 = await dashboard.getWidgetData('MostActiveUsers', this.filters);
            this.mostActiveUsers = response2.data;

            this.loadingUsers = false;
        },
    }
}

</script>
